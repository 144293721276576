@use "../services/servicesCommonStyles.scss";

.percentage {
  position: relative;
  display: inline-block;
}

.percentage::after {
  position: absolute;
  right: 12px;
  top: 1.5px;
  content: attr(data-placeholder);
  pointer-events: none;
}

.projects-dropdown {
  width: 170px;
}

.list-hover:hover {
  background: rgba(0, 0, 0, 0.3);
}
