/* Estilos de los informes*/
.table-report tbody tr td:first-child {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
}

.table-report tbody tr td:nth-last-child(1) {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-bottom: none;
}

.table-report tbody tr:nth-child(1) td {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-top: none;
}

.table-report tbody tr:nth-last-child(1) td {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
}

.table-report tbody tr:first-child td {
  border-top: none;
}

.table-report tbody tr:last-child td {
  border-bottom: none;
}

.table-report tbody tr td:first-child {
  border-left: none;
}

.table-report tbody tr td:last-child {
  border-right: none;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.report-button {
  height: 40px;
  width: 75%;
  background: rgba($color: #ffffff, $alpha: 0.09);
  border-radius: 6px;
  border: 0;
  align-items: center;
  margin-top: 30px;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.3);
}

.message {
  margin-top: 7px;
  padding: 0px 20px;
  text-align: center;
  margin-bottom: 0px;
}

/*estilos componente Statistics*/

.registrations-statistics {
  border: solid 1px transparent;
  padding: 0px 0px 5px 5px;
}

.registrations-statistics-title {
  font-size: 13px;
  text-align: center;
}

.registrations-statistics-number {
  font-size: 30px;
  font-weight: 400;
  text-align: center;
}

/* Estilos del indicadores*/
.overlay {
  height: 77%;
}

.container-indicators {
  background: rgba(0, 0, 0, 0.26);
  height: 99%;
  width: 99.7%;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 2px;
  display: block;
}

.container-header {
  padding: 88px 20px;
  background: rgba(0, 0, 0, 0.2);
  height: 10%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  color: white;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-registrations {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  height: 10%;
  width: 100%;
  margin-top: 10px;
}

.gauge-addional-info {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
}

/*Estilos Pie*/
.btns-options {
  width: 17%;
  height: 173px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.btn-indicators:hover,
.btn-indicators.active {
  background-color: #ffffff;
  color: black;
}

.echartsPie {
  width: 90%;
  height: 180px;
}

.header-pie {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  height: 10%;
  width: 100%;
  color: white;
}

.btn-indicators {
  border: none;
  border-radius: 5px 0px 0px 5px;
  width: 30px;
  background-color: rgb(0, 0, 0);
  color: white;
  margin: 5px 0px;
  transition: all 300ms ease-in;
}

.btn-indicators-second {
  border: none;
  border-radius: 0px 5px 5px 0px;
  width: 30px;
  background-color: rgb(0, 0, 0);
  color: white;
  margin: 5px 0px;
  transition: all 300ms ease-in;
}

/* Gauge Container */
.container-gauge {
  min-width: 80%;
  position: fixed;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

.gauge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 200px;
  height: 150PX;
  border-radius: 10px;
  margin-top: 20px;
}

.gauge-title-indicators {
  width: 100%;
  text-align: center;
  color: white;
  font-weight: 600;
}

.gauge-title {
  font-size: 20px;
  font-weight: 600;
  color: white;
  text-align: center;
}

/* Estilos del embudo de convertibilidad*/
.container-funnel {
  height: 99%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btn-funnel {
  border: none;
  margin: 1px 10px;
  border-radius: 5px;
  text-align: center;
  background-color: rgb(0, 0, 0, 0.2);
  padding: 10px;
  transition: all 300ms ease-out;
  margin-bottom: 30px;
}

.btn-funnel:hover,
.btn-funnel.active {
  background-color: rgb(3, 3, 3, 0.45);
  color: black;
}

.btn-text {
  margin: 0px;
  color: white;
}

.titleFunnel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.titleFunnel-text {
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 600;
  color: white;
  text-shadow: 1px 5px 9px black;
}

.funnel-graph {
  background-color: rgb(0, 0, 0, 0.2);
  margin: 5px;
  border-radius: 10px;
  padding: 15px;
  height: 45rem;
}

.funnel-header {
  color: white;
  display: grid;
  grid-template-columns: 5fr 2fr;
  align-items: center;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.custom-select:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

@media (max-width: 1600px) {

  // styles gauge
  .container-gauge {
    height: 425px;
    overflow: auto;
  }

  .overlay {
    height: 67%;
  }

  .registrations-statistics-title,
  .registrations-statistics-deference {
    font-size: 11px;
  }

  .registrations-statistics-number {
    font-size: 22px;
  }

  // styles funnels
  .btn-funnel {
    margin: 0px 10px;
  }

  .titleFunnel-text {
    margin: 0px;
  }

  .funnel-graph {
    padding: 0px;
    height: 36rem;
  }
}

@media (max-width: 1400px) {
  .container-gauge {
    height: 365px;
    overflow: auto;
  }

  .overlay {
    height: 67%;
  }

  .registrations-statistics-title,
  .registrations-statistics-deference {
    font-size: 9px;
  }

  .registrations-statistics-number {
    font-size: 19px;
  }
}

@media (max-width: 1280px) {
  .container-header {
    padding: 74px 20px;
  }

  .btns-options {
    height: 139px;
  }

  .registrations-statistics-title,
  .registrations-statistics-deference {
    font-size: 9.5px;
  }

  .registrations-statistics-number {
    font-size: 19px;
  }

  .container-gauge {
    height: 500px;
    overflow: auto;
  }

  .overlay {
    height: 67%;
  }
}


@media (min-width: 2500px) {
  .funnel-graph {
    background-color: rgb(0, 0, 0, 0.2);
    margin: 5px;
    border-radius: 10px;
    padding: 15px;
    height: 63rem;
  }
}

@media (max-width: 1504px) {
  .res-indicadores__generales {
    display: flex;
    flex-direction: column-reverse;
  }

  .res-graph__pie {
    width: 100% !important;
  }
}

@media (max-width: 1400px) {
  .res-indicadores__especificos {
    display: flex;
    flex-direction: column-reverse;
  }
}