@use "../servicesCommonStyles.scss";

.hover-link {
    color: #00bcd4;
    text-decoration: none;
    &:hover {
        color: #00bcd4;
        text-decoration: underline;
    }
}

.hover-white {
    color: #fff;
    text-decoration: none;
    &:hover {
        color: #fff;
        text-decoration: underline;
    }
}
