.modal-editor-container {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-start;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
  h1 {
    margin-left: 10px;
  }
}

.modal-editor-container2 {
  background-color: rgba(0, 0, 0, 0.15);
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-start;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
}
