.boton1 {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 5px 8px;
  margin-right: 5px;
  margin-left: 5px;
  border: none;
  color: white;
}

.boton1:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.boton1Selected {
  background-color: rgba(255, 255, 255, 0.322);
  border-radius: 8px;
  border-color: black;
  padding: 5px 8px;
  margin-right: 5px;
  margin-left: 5px;
  border: none;
  color: white;
}

.input1 {
  width: 300px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.editor-container1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  h1 {
    width: 300px;
  }
}

.editor-container2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
  width: 400px;
  h1 {
    width: 100px;
  }
}

.editor-containerFieldDetail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
  width: 400px;
  h1 {
    width: 100px;
    margin: 0px;
  }
}

.editor-container-fieldPreview {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: 2px;
  margin: 2px;
  border-radius: 10px;
  width: 400px;
  h1 {
    width: 60px;
  }
}
.containerFieldPreviewMaster {
  height: 150px;
  background-color: rgba(0, 0, 0, 0.2);
}

.containerFieldPreviewMasterSelected {
  height: 150px;
  background-color: rgba(0, 0, 0, 0.7);
}


.containerFieldPreviewMaster:hover {
  height: 150px;
  background-color: rgba(0, 0, 0, 0.7);
}
