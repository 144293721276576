/* Estilos de los contenedores de los gráficos */

.conteiner-indicators-card {
  border-radius: 0.5rem;
  margin-left: 1.25rem;
  padding: 0 0.625rem 0.625rem 0.625rem;
  width: 96%;
  background-color: rgba(0, 0, 0, 0.1);
}

.conteiner-indicators-dates {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.2;
  padding-left: 1.5rem;
  padding-top: 0.625rem;
}

.conteiner-indicators {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18.75rem, 1fr));
  gap: 1.25rem;
  padding: 1.25rem;
}

.button-style {
  background-color: transparent;
  border-radius: 0.5rem;
  margin-top: 0.625rem;
  padding: 0.625rem;
  border: none;
  width: 3.125rem;
  margin-bottom: 0.625rem;
}

.button-style:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.btn-filter {
  border-radius: 0.5rem 0 0 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: #ffffff;
  padding: 0.375rem;
  font-weight: 700;
  font-size: 0.8125rem;
  width: 6rem;
}

.btn-filter-unselected {
  border-radius: 0.5rem 0 0 0.5rem;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  color: #ffffff;
  padding: 0.375rem;
  font-weight: 700;
  font-size: 0.8125rem;
  width: 6rem;
}

.btn-filter-unselected:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.btn-filter-2 {
  border-radius: 0 0.5rem 0.5rem 0;
}

.btn-filter:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.graph-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1.25rem;
}

.graph-card {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  padding: 0.1rem;
}

.graph-card p {
  color: white;
  font-size: 1rem;
  text-align: center;
  font-weight: 700;
}

.download-container {
  flex: 1 1 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0.625rem;
}

.download-button {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0.5rem;
  border: none;
  padding: 0.625rem;
  font-size: 1.0625rem;
  font-weight: 700;
  cursor: pointer;
  width: 40%;
}

@media (max-width: 1794px) {
  .conteiner-indicators-card {
    margin-left: 10px;
    padding: 0 5px 5px 5px;
  }

  .graph-card {
    width: 100% !important;
    max-width: none;
  }
}

/* Estilos del componente TableComponent*/
.table-container {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.table {
  width: max-content;
  border-collapse: collapse;
  color: #ffffff;
  margin: 0 auto;
  text-align: center;
  min-width: 65%;
}

.table th,
.table td {
  padding: 8px;
  border: 0.5px solid rgba(56, 10, 223, 0);
  background-color: rgb(0, 0, 0, 0.3);
}

.table tbody tr:first-child td {
  border-top: none;
}

.table tbody tr:nth-child(1) td {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-top: none;
}

.table tbody tr:nth-child(2) td {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-top: none;
}

.table tbody tr:nth-child(3) td {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-top: none;
}

.table tbody tr:nth-child(4) td {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-top: none;
}

.table tbody tr td:last-child {
  border: 0.5px solid rgba(252, 255, 255, 0.2);
  border-right: none;
}

.table tbody tr:last-child td {
  border-bottom: none;
  border-right: none;
}

.table tbody tr td:first-child {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-left: none;
  border-bottom: none;
}

.table tbody tr:nth-child(odd) td {
  background-color: rgba(0, 0, 0, 0.1);
}

.table tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.total-cell {
  background: rgba(0, 0, 0, 0.3);
  font-weight: bold;
}

.table th:first-child {
  position: relative;
  width: 150px;
  height: 60px;
}

.table th:first-child .date-label {
  position: absolute;
  top: 5px;
  left: 65%;
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  transform: translateX(-50%);
}

.table th:first-child .title-label {
  position: absolute;
  bottom: 5px;
  left: 10%;
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-weight: 600;
}

.table th:first-child .diagonal-line {
  position: absolute;
  width: 100%;
  height: 1px;
  top: 50%;
  left: 2%;
  background: rgba(255, 255, 255, 0.3);
  transform: rotate(-160deg);
}

.download-container {
  flex: 1 1 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.download-button {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  border: none;
  padding: 10px;
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
  width: 40%;
}

@media (max-width: 1900px) {
  .table-container {
    flex-direction: column;
    gap: 5px;
  }

  .table {
    width: 70%;
  }

  .download-container {
    width: 100%;
    text-align: center;
  }

  .download-button {
    cursor: pointer;
    width: 25%;
  }
}

@media (max-width: 768px) {
  .table th,
  .table td {
    font-size: 14px;
    padding: 6px;
  }

  .download-button {
    font-size: 14px;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .table th:first-child .date-label,
  .table th:first-child .title-label {
    font-size: 12px;
  }

  .download-container {
    width: 100%;
  }
}

/* Overlay: Fondo oscuro semi-transparente */
.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal principal */
.modal-container {
  margin: 0 auto;
  height: auto;
  min-height: 12rem;
  min-width: 24rem;
  max-width: 28rem;
  border-radius: 0.5rem;
  background-color: #eeebeb;
  padding: 1.5rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  text-align: center;
}

.modal-content p {
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: bold;
  color: #0e0e0e;
}

.modal-message {
  margin-bottom: 1rem;
  color: #ffffff;
}

.modal-items {
  margin-top: 0.75rem;
}

.service-card {
  border: none;
  padding: 16px;
  border-radius: 8px;
  margin: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #ffffff;
  min-width: 250px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
}

.service-card__title {
  text-align: center;
  font-weight: 700;
}

.service-card__label {
  margin: 0;
  font-size: 11px;
}

.service-card__value {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 4px;
}

.service-card__constraint-list {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  font-size: 12px;
  padding: 4px 18px;
  margin: 4px 0;
}

.client-config__services {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.client-config__cutoff {
  min-width: 17%;
  height: 90%;
  border: none;
  padding: 16px;
  border-radius: 8px;
  margin: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 14px;
  color: #ffffff;
}

.client-config__title {
  text-align: center;
  font-weight: 700;
}

.client-config__value {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 4px;
}
